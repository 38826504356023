import React, { useState, useEffect, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useLazyQuery } from '@apollo/client';
import { getAuthStore } from 'Graphql/store/auth/getters';

import GET_LABELS_METADATA from 'Graphql/queries/config/labels/getLabelsMetadata';

const LabelsContext = createContext();

export const LabelsContextProvider = ({ children }) => {
  const { userId } = getAuthStore();
  const [allowedLabels, setAllowedLabels] = useState([]);
  const [fetchLabels, { data: labelsData, loading: gettingLabelsData }] = useLazyQuery(
    GET_LABELS_METADATA.query,
    { ...GET_LABELS_METADATA.policies },
  );

  const allowedLabel = (label) => allowedLabels.includes(label);

  const contextValue = useMemo(
    () => ({ allowedLabels, allowedLabel, refetchLabels: fetchLabels }),
    [allowedLabels, allowedLabel, fetchLabels],
  );

  useEffect(() => {
    if (userId) fetchLabels();
  }, [userId]);

  useEffect(() => {
    if (!gettingLabelsData && labelsData) {
      setAllowedLabels(
        labelsData?.getLabelMetadata
          ?.filter(({ classType, whiteList }) => {
            if (classType !== 'admins') return false;
            return whiteList.includes(userId);
          })
          .map(({ name }) => name) || [],
      );
    }
  }, [gettingLabelsData]);
  return <LabelsContext.Provider value={contextValue}>{children}</LabelsContext.Provider>;
};

LabelsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLabelsContext = () => useContext(LabelsContext);

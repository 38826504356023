const buildUri = () => {
  if (process.env.MODE !== 'development') return process.env.ADMIN_APP_URL;
  return 'http://localhost:8000';
};

const baseParams = {
  client_id: 'MfwiA_xwgxOXSD4zhtqdarH099d7L3mSI0A4n_J5t04',
  redirect_uri: `${buildUri()}/#/auth`,
};

export default baseParams;

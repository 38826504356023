import * as Sentry from '@sentry/react';
import { authStore } from './model';

const getExpirationDate = () => new Date().getTime() + 50 * 60 * 1000;

export const setExpirationDate = () => localStorage.setItem('exdt', getExpirationDate());

export const setRefreshToken = (refreshToken) => localStorage.setItem('rtn', refreshToken);

export const setAuthenticated = (authenticated) => localStorage.setItem('iad', authenticated);

export const setAuthStore = ({ access_token: accessToken, refresh_token: refreshToken }) => {
  setExpirationDate();
  setRefreshToken(refreshToken);
  authStore({ ...authStore(), accessToken });
};

export const setUserId = (userId) => {
  authStore({ ...authStore(), userId });
};

export const initAuthentication = ({ userId }) => {
  setAuthenticated(true);
  setUserId(userId);
};

export const setSession = ({ userId, userFirstName, userLastName }) => {
  Sentry.setContext('Admin', { userId, name: `${userFirstName} ${userLastName}`.trim() });
  authStore({ ...authStore(), userId, userFirstName, userLastName });
};

export const setPermissions = ({ permissions }) => {
  authStore({ ...authStore(), permissions });
};

export const logout = () => {
  localStorage.clear();
  authStore({
    ...authStore(),
    accessToken: null,
    userId: null,
    userFirstName: null,
    userLastName: null,
    permissions: {},
  });
};

import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LoadingScreen from 'Components/ui/LoadingScreen';
import AuthGuard from 'Components/AuthGuard';

const NotFound = lazy(() => import('Components/NotFound'));
const BasePage = lazy(() => import('./pages'));
const SignIn = lazy(() => import('./pages/auth/SignIn'));
const Settings = lazy(() => import('./pages/settings'));
const Dashboard = lazy(() => import('./pages/general/dashboard'));
const Profile = lazy(() => import('./pages/general/profile'));
const Admins = lazy(() => import('./pages/admin/admins'));
const Drivers = lazy(() => import('./pages/operations/fleetManagement/drivers'));
const Cars = lazy(() => import('./pages/operations/fleetManagement/cars'));
const Rents = lazy(() => import('./pages/operations/fleetManagement/rents'));
const RentQueue = lazy(() => import('./pages/operations/fleetManagement/rentQueue'));
const Retirements = lazy(() => import('./pages/operations/fleetManagement/retirements'));
const Assignations = lazy(() => import('./pages/operations/fleetManagement/assignations'));
const MarketPlace = lazy(() => import('./pages/operations/fleetManagement/marketPlace'));
const UberFleet = lazy(() => import('./pages/operations/fleetManagement/uberFleet'));
const ManageExchanges = lazy(() => import('./pages/operations/sharedPlans/manageExchanges'));
const ManageShiftChange = lazy(() => import('./pages/operations/sharedPlans/manageShiftChange'));
const MaintenanceAppointments = lazy(() => import('./pages/operations/maintenance'));
const ScheduleAppointment = lazy(() => import('./pages/operations/maintenance/Schedule'));
const TelemetryDevices = lazy(() => import('./pages/operations/telemetry/DevicesView'));
const LockUnlock = lazy(() => import('./pages/operations/telemetry/LockUnlock'));
const GPS = lazy(() => import('./pages/operations/telemetry/GPS'));
const Maps = lazy(() => import('./pages/operations/telemetry/maps'));
const ConfigPlans = lazy(() => import('./pages/operations/configurations/configPlans'));
const ConfigShifts = lazy(() => import('./pages/operations/configurations/shifts'));
const CarConfigs = lazy(() => import('./pages/operations/configurations/carConfigs'));
const ConfigLabels = lazy(() => import('./pages/operations/configurations/labels'));
const BillWeeks = lazy(() => import('./pages/managementFinance/collections/billweeks'));
const Reconciliations = lazy(() => import('./pages/managementFinance/collections/reconciliations'));
const Installments = lazy(() => import('./pages/managementFinance/collections/installments'));
const Events = lazy(() => import('./pages/support/events'));
const Tickets = lazy(() => import('./pages/support/tickets'));
const OperationReport = lazy(() => import('./pages/dataDashboards/OperationReport'));
const AccountingReport = lazy(() => import('./pages/dataDashboards/AccountingReport'));
const PerformanceReport = lazy(() => import('./pages/dataDashboards/PerformanceReport'));
const OwnerReport = lazy(() => import('./pages/dataDashboards/OwnerReport'));
const LayOut = lazy(() => import('./layouts/app'));

const Router = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<LoadingScreen fullScreen size="big" />}>
      <Routes location={location}>
        <Route path="404" element={<NotFound />} />
        <Route index element={<BasePage />} />
        <Route path="/auth" element={<SignIn />} />
        <Route path="/app/*" element={<AuthGuard />}>
          <Route element={<LayOut />}>
            <Route path="general/*">
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="admin/*">
              <Route path="admins" element={<Admins />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="commercial/*">
              <Route path="marketing/dummy1" element={<div />} />
              <Route path="customer-experience/dummy1" element={<div />} />
              <Route path="on-boarding/dummy1" element={<div />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="operations/*">
              <Route path="fleet-management/*">
                <Route path="drivers" element={<Drivers />} />
                <Route path="cars" element={<Cars />} />
                <Route path="rents" element={<Rents />} />
                <Route path="rent-queue" element={<RentQueue />} />
                <Route path="retirements" element={<Retirements />} />
                <Route path="assignations" element={<Assignations />} />
                <Route path="uber-fleet" element={<UberFleet />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="market-place" element={<MarketPlace />} />
              <Route path="shared-plans/*">
                <Route path="manage-exchanges" element={<ManageExchanges />} />
                <Route path="manage-shift-change" element={<ManageShiftChange />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="maintenance/*">
                <Route path="appointments" element={<MaintenanceAppointments />} />
                <Route path="schedule" element={<ScheduleAppointment />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="telemetry/*">
                <Route path="devices" element={<TelemetryDevices />} />
                <Route path="lock-unlock" element={<LockUnlock />} />
                <Route path="gps" element={<GPS />} />
                <Route path="maps" element={<Maps />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="configuration/*">
                <Route path="plans" element={<ConfigPlans />} />
                <Route path="shifts" element={<ConfigShifts />} />
                <Route path="car-configs" element={<CarConfigs />} />
                <Route path="labels" element={<ConfigLabels />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="management-finance/*">
              <Route path="collections/*">
                <Route path="bill-weeks" element={<BillWeeks />} />
                <Route path="reconciliations" element={<Reconciliations />} />
                <Route path="installments" element={<Installments />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="support/*">
              <Route path="events" element={<Events />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="data/*">
              <Route path="operation" element={<OperationReport />} />
              <Route path="accounting" element={<AccountingReport />} />
              <Route path="performance" element={<PerformanceReport />} />
              <Route path="owners" element={<OwnerReport />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
